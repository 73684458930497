import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box, colors, Button, Large } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../Wrapper';
import { requestDemo } from '../../common/Util';
import G2CrowdTraits from './g2CrowdTraits.svg';
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../../components/PageTitle';

function CallToAction() {
  return (
    <Section flexDirection="column">
        <WidthImg src={G2CrowdTraits} alt="Rated #1 for ease of use" />
        <a href="./customers">
        <CustomerButton>
          <PageRegular>
            See What Our Customers Are Saying
          </PageRegular>
        </CustomerButton>
        </a>
        
    </Section>
  );
}

const WidthImg = styled.img`
  width: 100%;
`;

const DividerLine = styled.div`
  width: 100%;
  opacity: 30%;
  height: 2px;
  background-color: ${colors.white};
  margin: 32px 0;
`;

const CustomerButton = styled.div`
  background-color: rgba(255,255,255,0.1);
  color: white;
  padding: 16px 22px;
  border: rgba(255,255,255,0.3) solid 2px;
  border-radius: 120px;
  transition: 1s all;
  cursor: pointer;

  :hover {
    background-color: rgba(255,255,255,1);
    transition: 0.3s all;
    transform: scale(1.1);
    color: ${colors.trulyBlue};
  }
`;

const GetStartedButton = styled.div`
  background: linear-gradient(90deg, rgba(195,243,206,1) 0%, rgba(133,231,155,1) 100%);
  color: #0D7124;
  padding: 20px 32px 16px 32px;
  border-radius: 120px;
  transition: 1s all;
  cursor: pointer;

  :hover {
    transform: scale(1.1);
  }
`;

export default CallToAction;