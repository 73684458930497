import React, { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Box, Button, colors, FlexRow } from 'js-components';
import { Link } from 'gatsby';
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper';
import { CallToAction, GetStarted } from '../../components/CallToAction';
import { mobileBreakpoint } from '../../constants';
import { PageTitle2, PageTitle3, PageLabel, PageParagraph } from '../../components/PageTitle';
import Default from '../../components/Layouts/Default';

import Admin from './featureImages/admin.png';
import AeMode from './featureImages/aeMode.png';
import AllDevices from './featureImages/allDevices.png';
import Sfdc from './featureImages/sfdc.png';
import Coach from './featureImages/coach.png';
import Availability from './featureImages/availability.png';
import CallControls from './featureImages/callControls.png';
import DevicePicker from './featureImages/devicePicker.png';
import PhoneNumberPicker from './featureImages/phoneNumberPicker.png';
import PictureMessaging from './featureImages/pictureMessaging.png';
import SalesforceDashboard from './featureImages/salesforceDashboard.png';
import Secure from './featureImages/secure.png';
import SignOn from './featureImages/signOn.png';
import Coaching from './featureImages/coaching.png';
import Network from './featureImages/network.png';
import AdminPhoneMenu from './featureImages/adminPhoneMenu.png';
import AdminForms from './featureImages/adminForms.png';


import { requestDemo } from '../../common/Util';

const getImage = image => {
  switch (image) {
    default:
    case 'aeMode':
      return AeMode;
    case 'allDevices':
      return AllDevices;
    case 'sfdc':
      return Sfdc;
    case 'admin':
      return Admin;
    case 'coach':
      return Coach;
    case 'availability':
      return Availability;
    case 'callControls':
      return CallControls;
    case 'devicePicker':
      return DevicePicker;
    case 'phoneNumberPicker':
      return PhoneNumberPicker;
    case 'pictureMessaging':
      return PictureMessaging;
    case 'salesforceDashboard':
      return SalesforceDashboard;
    case 'secure':
      return Secure;
    case 'signOn':
      return SignOn;
    case 'coaching':
      return Coaching;
    case 'network':
      return Network;
    case 'adminPhoneMenu':
      return AdminPhoneMenu;
    case 'adminForms':
      return AdminForms;
  }
};

export default class FeaturePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: props.pageContext.type
    };
  }

  onFeatureSelectChange = e => this.setState({ selectedType: e.target.value });

  render() {
    const featName = this.props.pageContext.name;
    const otherFeatures = this.props.pageContext.features.filter(feat => {
      return feat.type === this.state.selectedType;
    });

    const featureTypes = this.props.pageContext.features.reduce((acc, feat) => {
      if (!acc.includes(feat.type)) {
        acc.push(feat.type);
      }
      return acc;
    }, []);

    return (
      <Default>
        <Helmet>
          <title>{featName}</title>
        </Helmet>

        <WrapperSolid>
          <FeatureContainer>
            <FeatureNavContainer>
              <FeatureSelectText as="p" color={colors.trulyDark} mb="16pt" center>
                Explore
                <FeatureSelect onChange={this.onFeatureSelectChange}>
                  {featureTypes.map(type => (
                    <option selected={type === this.state.selectedType} value={type}>
                      {type}
                    </option>
                  ))}
                </FeatureSelect>
                Features
              </FeatureSelectText>
              <FeatureList>
                {otherFeatures.map(feature => {
                  return (
                    <FeatureRow to={feature.url} key={feature.name} selectedFeature={feature.name === featName}>
                      {feature.name}
                    </FeatureRow>
                  );
                })}
              </FeatureList>
            </FeatureNavContainer>
            <FeatureContentContainerOuter>
              <FeatureContentContainer>
                  <WidthImg src={getImage(this.props.pageContext.image)} />
                  <PageLabel as="h2" color={colors.accentLavender} bold left mb="8pt">
                    {this.props.pageContext.type}
                  </PageLabel>
                  <PageTitle3 as="p" color={colors.trulyDark} mb="8pt" center>
                    {this.props.pageContext.name}
                  </PageTitle3>
                  <PageParagraph
                    color={colors.trulyDark}
                    dangerouslySetInnerHTML={{ __html: this.props.pageContext.description }}
                  ></PageParagraph>
              </FeatureContentContainer>
            </FeatureContentContainerOuter>
          </FeatureContainer>
        </WrapperSolid>


        <WrapperBackground background="headsetPerson1" gradientType="purple">
          <CallToAction/>
        </WrapperBackground>

        <WrapperBackground background="sf" gradientType="black">
          <GetStarted/>
        </WrapperBackground>
      </Default>
    );
  }
}

const FeatureContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: ${mobileBreakpoint}) {
    flex-direction: column;
  }
`;

const FeatureSelect = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 10px;
  background-color: #fafafa;
  color: ${colors.accentPurple};
  border: none;
  font-size: 9pt;
  font-weight: bold;
  margin: 12pt;
  transition: 0.2s all;
  cursor: pointer;
  border: 1px solid #CBD0D8;

  select {
    display: none;
  }

  :hover {
    transform: scale(1.02);
    transition: 0.2s all;
  }
`;

const FeatureNavContainer = styled(Box)`
  border-right: 1px solid #CBD0D8;
  padding: 16px 32px 64px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 360px;

  @media (max-width: ${mobileBreakpoint}) {
    order: 2;
    border-right: none;
    background: #fafafa;
    width: 100%;
  }
`;

const FeatureContentContainer = styled(Box)`
  padding: 0 64px 64px;
  max-width: 812px;
`;

const FeatureContentContainerOuter = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileBreakpoint}) {
    order: 1;
  }
`;

const FeatureSelectText = styled(PageTitle3)`
  font-size: 9pt;
  margin-bottom: 6px;
  margin-top: 20px;
`;

const FeatureList = styled(FlexRow)`
  justify-content: center;
  flex-wrap: wrap;
`;

const FeatureRow = styled(Link)`
  display: flex;
  align-items: center;
  padding: 8pt 16pt 6pt 16pt;
  margin: 4pt;
  box-shadow: 0 2pt 6pt ${colors.rgba(colors.trulyDark, 0.1)};
  background-color: white;
  cursor: pointer;
  color: ${colors.accentLavender};
  border-radius: 32pt;
  transition: 0.2s all;
  line-height: 19pt;

  ${props => props.selectedFeature && `
    background-color: ${colors.accentLavender};
    color: white;
    transition: 0.2s all;
  `}

  :hover {
    transition: 0.5s all;
    box-shadow: 0 2pt 6pt ${colors.rgba(colors.trulyDark, 0.2)};
  }

  width: 100%;
`;

const WidthImg = styled.img`
  width: 100%;
`;
