import styled from 'styled-components'
import { Title1, Regular, Small } from 'js-components'

export const PageTitle = styled(Title1)`
  ${props => `color: ${props.color};`}
  font-size: 38pt;
  line-height: 44pt;
  font-weight: 400;

  @media (max-width: 400pt) {
    font-size: 16pt;
    line-height: 19pt;
  }
`

export const PageTitle2 = styled(Title1)`
  ${props => `color: ${props.color};`}
  text-transform: capitalize;
  font-size: 30pt;
  line-height: 34pt;
  font-weight: 300;

  @media (max-width: 400pt) {
    font-size: 12pt;
    line-height: 16pt;
  }
`

export const PageTitle3 = styled(Title1)`
  ${props => `color: ${props.color};`}
  font-size: 22pt;
  line-height: 24pt;
  margin-top:10px
  width: 100%;
`

export const PageLabel = styled(Title1)`
  ${props => `color: ${props.color};`}
  font-size: 12pt;
  line-height: 14pt;
  font-weight: bold;
`

export const PageParagraph = styled(Regular)`
  ${props => `color: ${props.color};`}
  text-style: regular;
  font-size: 16pt;
  line-height: 20pt;
`

export const PageRegular = styled(Regular)`
  ${props => `color: ${props.color};`}
  text-style: regular;
  font-size: 12pt;
  line-height: 14pt;
`

export const PageSmall = styled(Small)`
  ${props => `color: ${props.color};`}
  font-size: 10pt;
  line-height: 12pt;
`

