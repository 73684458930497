import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Box, colors, Button, Large } from 'js-components';
import { Section, WrapperBackground, WrapperSolid } from '../Wrapper';
import { requestDemo } from '../../common/Util';
import G2CrowdTraits from './g2CrowdTraits.svg';
import { PageTitle, PageTitle2, PageTitle3, PageParagraph, PageRegular, PageLabel, PageSmall } from '../PageTitle';

function GetStarted() {
  return (
    <Section flexDirection="column">
        <PageTitle3 pt="16px" pb="16px" color={colors.white}>Let us show you how AI Enabled Bots can drive results in just 7 days.</PageTitle3>
        <a href="https://hello.truly.co/book-a-meeting">
        <GetStartedButton>
          <PageTitle3>
            Request A Demo
          </PageTitle3>
        </GetStartedButton>
        </a>    
    </Section>
  );
}

const GetStartedButton = styled.div`
  background-color: #CEF5FF;
  color: ${colors.trulyDark};
  padding: 20px 32px 16px 32px;
  border-radius: 120px;
  transition: 0.3s all;
  cursor: pointer;

  :hover {
    transform: scale(1.025);
  }
`;

export default GetStarted;